import { FC } from "react"
import { useRouter } from "next/router"
// Components
import { MainWrapper, Title, Description } from "./index.styles"
// Libs
import { useAppDispatch } from "@hooks/app"
import { fieldStatus } from "@constants"
import { useTranslation } from "next-i18next"
// Styles

interface IProps {
  postList?: any
}

const HomePage: FC<IProps> = (props) => {
  const { postList } = props

  const dispatch = useAppDispatch()
  const { t } = useTranslation(["common"])
  const { locale, locales, defaultLocale } = useRouter()
  
  return (
    <>
      <MainWrapper></MainWrapper>
    </>
  )
}

export default HomePage
